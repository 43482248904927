import { bindable, inject }            from 'aurelia-framework';
import { AppContainer }                from 'resources/services/app-container';
import { BaseListViewModel }           from 'base-list-view-model';
import { DialogService }               from 'aurelia-dialog';
import { Downloader }                  from 'resources/services/downloader';
import { DisassembledLiftsRepository } from 'modules/lifts/disassembled/services/repository';
import { FilterFormSchema }            from 'modules/lifts/disassembled/filter-form-schema';
import { ViewLiftCertificates }        from 'modules/lifts/lifts/certificates/index';
import { ViewLiftChecklist }           from 'modules/lifts/lifts/checklists/index';
import { ViewLiftHistory }             from 'modules/lifts/lifts/history/index';

@inject(AppContainer, DisassembledLiftsRepository, DialogService, Downloader, FilterFormSchema)
export class ListDisassembledLifts extends BaseListViewModel {

    listingId = 'lifts-disassembled-listing';

    @bindable headerTitle = 'listing.lifts.disassembled';
    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param dialogService
     * @param downloader
     * @param filterFormSchema
     */
    constructor(appContainer, repository, dialogService, downloader, filterFormSchema) {
        super(appContainer);

        this.repository       = repository;
        this.dialogService    = dialogService;
        this.downloader       = downloader;
        this.filterFormSchema = filterFormSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'lifts.lifts.manage',
            'lifts.lifts.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.filterModel  = this.filterFormSchema.model(this);
        this.filterSchema = this.filterFormSchema.schema(this);

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            resource:           'resource.lifts.lift',
            repository:         this.repository,
            show:               {
                action:  true,
                visible: (row) => this.appContainer.authenticatedUser.can(['lifts.lifts.manage', 'lifts.lifts.view']),
            },
            actions:            [
                {
                    icon:    'icon-stack-check',
                    tooltip: 'form.button.checklist',
                    action:  (row) => this.openLiftChecklist(row),
                    visible: (row) => this.appContainer.authenticatedUser.can(['lifts.lifts.manage', 'lifts.lifts.view']),
                },
                {
                    icon:    'icon-history',
                    tooltip: 'form.button.history',
                    action:  (row) => this.openLiftHistory(row),
                    visible: (row) => this.appContainer.authenticatedUser.can(['lifts.lifts.manage', 'lifts.lifts.view']),
                },
                {
                    icon:    'icon-certificate',
                    tooltip: 'form.button.certificates',
                    action:  (row) => this.openLiftCertificates(row),
                    visible: (row) => this.appContainer.authenticatedUser.can(['lifts.lifts.manage', 'lifts.lifts.view']),
                },
            ],
            options:            [
                {
                    label:  'form.button.export-to-excel',
                    icon:   'icon-file-excel',
                    action: () => this.exportListing('ascensores'),
                },
            ],
            buttons:            [],
            actionsContextMenu: true,
            selectable:         true,
            sorting:            {
                column:    0,
                direction: 'asc',
            },
            columns:            [
                {
                    data:   'code',
                    name:   'lifts.code',
                    title:  'form.field.lift-number',
                    type:   'link',
                    action: (row) => this.appContainer.router.navigateToRoute('lifts.disassembled.view', { id: row.id }),
                },
                {
                    data:  'lift_type_name',
                    name:  'lift_types.name',
                    title: 'form.field.lift-type',
                },
                {
                    data:  'address',
                    name:  'lifts.address',
                    title: 'form.field.address',
                },
                {
                    data:  'parish_name',
                    name:  'parishes.name',
                    title: 'form.field.parish',
                },
                {
                    data:  'current_owner_name',
                    name:  'owner_data.name',
                    title: 'form.field.owner/administrator',
                },
            ],
        };
    }

    /**
     * Opens the lift checklist modal
     *
     * @param row
     */
    openLiftChecklist(row) {
        this.dialogService.open({ viewModel: ViewLiftChecklist, model: row });
    }

    /**
     * Opens the lift history modal
     *
     * @param row
     */
    openLiftHistory(row) {
        this.dialogService.open({ viewModel: ViewLiftHistory, model: row });
    }

    /**
     * Opens the lift certificates modal
     *
     * @param row
     */
    openLiftCertificates(row) {
        this.dialogService.open({ viewModel: ViewLiftCertificates, model: row });
    }

}
