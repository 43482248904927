import { bindable, inject }    from 'aurelia-framework';
import { AppContainer }        from 'resources/services/app-container';
import { BaseFormViewModel }   from 'base-form-view-model';
import { LiftCodesRepository } from 'modules/lifts/lifts/code/services/repository';
import { FormSchema }          from 'modules/lifts/lifts/code/form-schema';
import { DialogController }    from 'aurelia-dialog';

@inject(AppContainer, LiftCodesRepository, FormSchema, DialogController)
export class EditLiftCode extends BaseFormViewModel {

    formId = 'lifts.lifts.code.edition-form';

    @bindable alert;
    @bindable settings = {
        title: 'form.title.edit-lift-code',
        size:  'modal-md',
    };

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param dialogController
     * @param formSchema
     */
    constructor(appContainer, repository, formSchema, dialogController) {
        super(appContainer);

        this.repository       = repository;
        this.formSchema       = formSchema;
        this.dialogController = dialogController;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can(['lifts.lifts.manage']);
    }

    /**
     * Handles activate event
     */
    activate(lift) {
        this.lift = lift;

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.fetchData(lift).then(() => this.schema = this.formSchema.schema(this));
    }

    /**
     * Submits view form
     */
    submit() {
        this.alert = null;

        // calls repository test method
        return this.repository
            .update(this.lift.id, this.model)
            .then(response => this.afterSubmitCallback(response))
            .then(response => response.status)
            .catch(console.log.bind(console));
    }

    /**
     * Handles the submit form response
     *
     * @param response
     */
    afterSubmitCallback(response) {
        if (response.status === true) {
            this.appContainer.notifier.successNotice(response.message);

            this.lift.district_id      = this.model.district_id;
            this.lift.county_id        = this.model.county_id;
            this.lift.parish_id        = this.model.parish_id;
            this.lift.code_incrementer = this.model.code_incrementer;
            this.lift.code             = response.model.code;

            this.initialModel.assign(this.model);
        } else {
            this.alert = this.alertMessage(response.status, response.message, response.errors);
        }

        return response;
    }

    /**
     * Fetches data from server
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    fetchData(params) {
        return Promise.all([
            this.assignInitialModels(params),
        ]);
    }

    /**
     * Assigns the inital models
     */
    assignInitialModels(lift) {
        let assignable = {
            district_id:      lift.district_id,
            county_id:        lift.county_id,
            parish_id:        lift.parish_id,
            code_incrementer: lift.code_incrementer,
        };

        this.initialModel.assign(assignable);
        this.model.assign(assignable);
    }

}
