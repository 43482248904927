import { bindable, inject }         from 'aurelia-framework';
import { AppContainer }             from 'resources/services/app-container';
import { BaseFormViewModel }        from 'base-form-view-model';
import { DialogController }         from 'aurelia-dialog';
import { LiftChecklistsRepository } from 'modules/lifts/lifts/checklists/services/repository';

@inject(AppContainer, DialogController, LiftChecklistsRepository)
export class ViewLiftChecklist extends BaseFormViewModel {

    formId = 'lifts.lifts.checklists.view-form';

    @bindable alert;
    @bindable settings = {
        title:   'form.title.view-lift-checklist',
        size:    'modal-lg',
        buttons: false,
    };

    treeOptions = {
        id:    'lifts-lifts-checklist-listing-tree',
        click: this.treeNodeClicked.bind(this),
    };

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogController
     * @param repository
     */
    constructor(appContainer, dialogController, repository) {
        super(appContainer);

        this.dialogController = dialogController;
        this.repository       = repository;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'lifts.lifts.manage',
            'lifts.lifts.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params) {
        this.settings.subtitle = params.code;

        this.treeOptions.repository = {
            source: this.repository,
            method: 'find',
            params: params.id,
        };

        return this.fetchData(params);
    }

    /**
     * Handles tree node click event
     *
     * @param event
     * @param data
     */
    treeNodeClicked(event, data) {
        let activeNode = data.tree.getActiveNode();

        if (activeNode === data.node) {
            activeNode.setFocus(false);
            activeNode.setActive(false);

            return false;
        }
    }

    /**
     * Closes the modal
     */
    cancel() {
        return this.dialogController.cancel();
    }

}
