import { bindable, inject }                  from 'aurelia-framework';
import { AppContainer }                      from 'resources/services/app-container';
import { BaseListViewModel }                 from 'base-list-view-model';
import { Downloader }                        from 'resources/services/downloader';
import { AwaitingValidationLiftsRepository } from 'modules/lifts/awaiting-validation/services/repository';
import { FilterFormSchema }                  from 'modules/lifts/awaiting-validation/filter-form-schema';

@inject(AppContainer, Downloader, AwaitingValidationLiftsRepository, FilterFormSchema)
export class ListAwaitingValidationLifts extends BaseListViewModel {

    listingId = 'lifts-awaiting-validation-listing';

    @bindable headerTitle = 'listing.lifts.awaiting-validation';
    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param downloader
     * @param repository
     * @param filterFormSchema
     */
    constructor(appContainer, downloader, repository, filterFormSchema) {
        super(appContainer);

        this.downloader       = downloader;
        this.repository       = repository;
        this.filterFormSchema = filterFormSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'lifts.lifts.manage',
            'lifts.lifts.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.filterModel  = this.filterFormSchema.model(this);
        this.filterSchema = this.filterFormSchema.schema(this);

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            resource:   'resource.lifts.lift',
            repository: this.repository,
            show:       {
                action:  true,
                visible: (row) => this.appContainer.authenticatedUser.can(['lifts.lifts.manage', 'lifts.lifts.view']),
            },
            edit:       {
                action:  (row) => this.redirectToRoute({name: 'lifts.awaiting-validation.validate', params: {id: row.id}}),
                visible: (row) => this.appContainer.authenticatedUser.can(['lifts.lifts.manage', 'lifts.lifts.edit']),
            },
            actions:    [],
            options:    [
                {
                    label:  'form.button.export-to-excel',
                    icon:   'icon-file-excel',
                    action: () => this.exportListing('ascensores'),
                },
            ],
            buttons:    [],
            selectable: true,
            sorting:    {
                column:    0,
                direction: 'asc',
            },
            columns:    [
                {
                    data:  'lift_type_name',
                    name:  'lift_types.name',
                    title: 'form.field.lift-type',
                },
                {
                    data:  'address',
                    name:  'lifts.address',
                    title: 'form.field.address',
                },
                {
                    data:  'parish_name',
                    name:  'parishes.name',
                    title: 'form.field.parish',
                },
                {
                    data:  'current_owner_name',
                    name:  'owner_data.name',
                    title: 'form.field.owner/administrator',
                },
            ],
        };
    }

}
