import { bindable, customElement, inject } from 'aurelia-framework';
import { AppContainer }                    from 'resources/services/app-container';
import { BaseViewModel }                   from 'base-view-model';
import { LiftCertificatesRepository }      from 'modules/lifts/lifts/certificates/list/services/repository';

@customElement('lift-certificates')
@inject(AppContainer, LiftCertificatesRepository)
export class ListLiftCertificates extends BaseViewModel {

    listingId = 'lifts-lift-certificates-listing';

    @bindable lift;
    repository;
    datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     */
    constructor(appContainer, repository) {
        super(appContainer);

        this.repository = repository;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'lifts.lifts.manage',
            'lifts.lifts.view',
        ]);
    }

    /**
     * Handles activate event
     */
    bind() {
        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:      {
                search: (criteria) => this.repository.search(this.lift.id, criteria),
            },
            destroy:         false,
            destroySelected: false,
            actions:         [],
            options:         [],
            buttons:         [],
            selectable:      false,
            sorting:         {
                column:    0,
                direction: 'desc',
            },
            columns:         [
                {
                    data:            'deadline',
                    name:            'lift_certificates.deadline',
                    title:           'form.field.certificate-deadline',
                    valueConverters: [
                        {
                            name: 'dateFormat',
                        },
                    ],
                },
                {
                    data:            'created_at',
                    name:            'lift_certificates.created_at',
                    title:           'form.field.registered-at',
                    valueConverters: [
                        {
                            name: 'dateFormat',
                        },
                    ],
                },
                {
                    data:  'creator',
                    name:  'users.name',
                    title: 'form.field.registered-by',
                },
            ],
        };
    }

}
