import { inject }                                  from 'aurelia-framework';
import { BuildingTypesRepository }                 from 'modules/management/building-types/services/repository';
import { ControllingEntitiesRepository }           from 'modules/entities/controlling-entities/services/repository';
import { CreateInstallingCompanyModal }            from 'modules/entities/installing-companies/create-modal/index';
import { DialogService }                           from 'aurelia-dialog';
import { InspectionPeriodicitiesRepository }       from 'modules/management/inspection-periodicities/services/repository';
import { InstallingCompaniesRepository }           from 'modules/entities/installing-companies/services/repository';
import { Lift }                                    from 'modules/lifts/models/lift';
import { LiftCodesRepository }                     from 'modules/lifts/lifts/code/services/repository';
import { LiftTypesRepository }                     from 'modules/management/lift-types/services/repository';
import { MaintenanceCompaniesRepository }          from 'modules/entities/maintenance-companies/services/repository';
import { ManagingEntitiesRepository }              from 'modules/entities/managing-entities/services/repository';
import { OwnersRepository }                        from 'modules/entities/owners/services/repository';
import { SingleFormSchema as LocationsFormSchema } from 'modules/administration/locations/reusable/single-form-schema';

@inject(
    LiftCodesRepository,
    LiftTypesRepository,
    BuildingTypesRepository,
    ManagingEntitiesRepository,
    ControllingEntitiesRepository,
    InstallingCompaniesRepository,
    MaintenanceCompaniesRepository,
    InspectionPeriodicitiesRepository,
    OwnersRepository,
    LocationsFormSchema,
    DialogService,
)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {};

    /**
     * Returns a new instance of the model
     *
     * @returns {Lift}
     */
    model() {
        let model = new Lift();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Constructor
     *
     * @param liftCodesRepository
     * @param liftTypesRepository
     * @param buildingTypesRepository
     * @param managingEntitiesRepository
     * @param controllingEntitiesRepository
     * @param installingCompaniesRepository
     * @param maintenanceCompaniesRepository
     * @param inspectionPeriodicitiesRepository
     * @param ownersRepository
     * @param locationsFormSchema
     * @param dialogService
     */
    constructor(
        liftCodesRepository,
        liftTypesRepository,
        buildingTypesRepository,
        managingEntitiesRepository,
        controllingEntitiesRepository,
        installingCompaniesRepository,
        maintenanceCompaniesRepository,
        inspectionPeriodicitiesRepository,
        ownersRepository,
        locationsFormSchema,
        dialogService,
    ) {
        this.liftCodesRepository               = liftCodesRepository;
        this.liftTypesRepository               = liftTypesRepository;
        this.buildingTypesRepository           = buildingTypesRepository;
        this.managingEntitiesRepository        = managingEntitiesRepository;
        this.controllingEntitiesRepository     = controllingEntitiesRepository;
        this.installingCompaniesRepository     = installingCompaniesRepository;
        this.maintenanceCompaniesRepository    = maintenanceCompaniesRepository;
        this.inspectionPeriodicitiesRepository = inspectionPeriodicitiesRepository;
        this.ownersRepository                  = ownersRepository;
        this.locationsFormSchema               = locationsFormSchema;
        this.dialogService                     = dialogService;
    }

    /**
     * Returns lift identification form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    liftIdentificationSchema(viewModel) {
        this.code = {
            type:       'text',
            key:        'code',
            label:      'form.field.lift-number',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
            inputGroup: {
                position: 'right',
                button:   {
                    title:   'form.button.generate-number',
                    action:  () => this.generateCode(viewModel),
                    icon:    {class: 'icon-reload-alt'},
                    visible: true,
                },
            },
        };

        this.controlling_entity_id = {
            type:         'select2',
            key:          'controlling_entity_id',
            label:        'form.field.controlling-entity',
            size:         4,
            options:      [],
            remoteSource: this.controllingEntitiesRepository.active.bind(this.controllingEntitiesRepository),
            hidden:       !(viewModel.appContainer.authenticatedUser.can(['lifts.lifts.manage']) || viewModel.appContainer.authenticatedUser.belongsToManagingEntity()),
        };

        this.installing_company_id = {
            type:         'select2',
            key:          'installing_company_id',
            label:        'form.field.installing-company',
            size:         4,
            options:      [],
            remoteSource: this.installingCompaniesRepository.active.bind(this.installingCompaniesRepository),
            inputGroup:   {
                position: 'right',
                button:   {
                    title:   'form.button.create-new',
                    action:  () => this.createNewInstallingCompany(viewModel),
                    icon:    { class: 'icon-plus3' },
                    visible: viewModel.appContainer.authenticatedUser.can(['entities.installing-companies.manage', 'entities.installing-companies.create']),
                },
            },
        };

        this.owner_id = {
            type:         'select2',
            key:          'owner_id',
            label:        'form.field.owner/administrator',
            size:         4,
            options:      [],
            remoteSource: this.ownersRepository.active.bind(this.ownersRepository),
        };

        this.maintenance_company_id = {
            type:         'select2',
            key:          'maintenance_company_id',
            label:        'form.field.maintenance-company',
            size:         4,
            options:      [],
            remoteSource: this.maintenanceCompaniesRepository.active.bind(this.maintenanceCompaniesRepository),
        };

        this.address = {
            type:  'text',
            key:   'address',
            label: 'form.field.address',
            size:  12,
        };

        this.zip_code = {
            type:  'text',
            key:   'zip_code',
            label: 'form.field.zip-code',
            size:  2,
        };

        this.locality = {
            type:  'text',
            key:   'locality',
            label: 'form.field.locality',
            size:  6,
        };

        this.latitude = {
            type:  'text',
            key:   'latitude',
            label: 'form.field.latitude',
            size:  2,
        };

        this.longitude = {
            type:  'text',
            key:   'longitude',
            label: 'form.field.longitude',
            size:  2,
        };

        return [
            [this.code, this.controlling_entity_id],
            [this.installing_company_id, this.owner_id, this.maintenance_company_id],
            ...this.locationsFormSchema.schema(viewModel.model, true),
            [this.address],
            [this.zip_code, this.locality, this.latitude, this.longitude],
        ];
    }

    /**
     * Returns lift characteristics form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    liftCharacteristicsSchema(viewModel) {
        this.lift_type_id = {
            type:         'select2',
            key:          'lift_type_id',
            label:        'form.field.lift-type',
            size:         6,
            options:      [],
            remoteSource: this.liftTypesRepository.active.bind(this.liftTypesRepository),
            observers:    [
                () => {
                    if (this.applicable_regulation_id.instance) {
                        this.applicable_regulation_id.options.splice(0, this.applicable_regulation_id.options.length);
                        this.applicable_regulation_id.instance.fetchData();
                    }
                },
                () => this.updateInspectionPeriodicity(viewModel),
            ],
        };

        this.building_type_id = {
            type:         'select2',
            key:          'building_type_id',
            label:        'form.field.building-type',
            size:         6,
            options:      [],
            remoteSource: this.buildingTypesRepository.active.bind(this.buildingTypesRepository),
            observers:    [
                () => this.updateInspectionPeriodicity(viewModel),
            ],
        };

        this.applicable_regulation_id = {
            type:                   'select2',
            key:                    'applicable_regulation_id',
            label:                  'form.field.applicable-regulation',
            size:                   6,
            options:                [],
            remoteSource:           this.liftTypesRepository.applicableRegulations.bind(this.liftTypesRepository),
            remoteSourceParameters: () => viewModel.model.lift_type_id,
            hidden:                 !(viewModel.appContainer.authenticatedUser.can(['lifts.lifts.manage']) || viewModel.appContainer.authenticatedUser.belongsToManagingEntity()),
        };

        this.capacity = {
            type:  'number',
            key:   'capacity',
            label: 'form.field.capacity(kg)',
            size:  3,
        };

        this.person_capacity = {
            type:  'number',
            key:   'person_capacity',
            label: 'form.field.capacity(persons)',
            size:  3,
        };

        this.width = {
            type:  'number',
            key:   'width',
            label: 'form.field.width(mm)',
            size:  3,
        };

        this.unevenness = {
            type:  'number',
            key:   'unevenness',
            label: 'form.field.unevenness(degrees)',
            size:  3,
        };

        this.velocity = {
            type:  'number',
            key:   'velocity',
            label: 'form.field.velocity(m/s)',
            size:  3,
        };

        this.number_of_floors = {
            type:  'number',
            key:   'number_of_floors',
            label: 'form.field.number-of-floors',
            size:  3,
        };

        this.number_of_accesses = {
            type:  'number',
            key:   'number_of_accesses',
            label: 'form.field.number-of-accesses',
            size:  3,
        };

        this.certificate_issued_at = {
            type:  'material-ui-date-picker',
            key:   'certificate_issued_at',
            label: 'form.field.certificate-issued-at',
            size:  3,
        };

        this.inspection_periodicity = {
            type:  'number',
            key:   'inspection_periodicity',
            label: 'form.field.inspection-periodicity(years)',
            size:  3,
        };

        return [
            [this.lift_type_id, this.building_type_id],
            [this.applicable_regulation_id, this.certificate_issued_at, this.inspection_periodicity],
            [this.capacity, this.person_capacity, this.width, this.unevenness],
            [this.velocity, this.number_of_floors, this.number_of_accesses],
        ];
    }

    /**
     * Returns global buttons form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    globalButtonsSchema(viewModel) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     (event) => viewModel.redirectToRoute('lifts.lifts.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     (event) => viewModel.submit(),
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

    /**
     * Requests the generation of the next lift number
     *
     * @param {*} viewModel
     */
    generateCode(viewModel) {
        viewModel.alert = null;

        if (!viewModel.model.county_id) {
            return viewModel.alert = viewModel.warningAlertMessage('text.error-message.a-county-must-be-selected-to-generate-new-lift-number');
        }

        return this.liftCodesRepository.generateCode(viewModel.model.county_id).then(response => viewModel.model.code = response.code);
    }

    /**
     * Updated inspection periodicity, given the lift type and building type
     *
     * @param viewModel
     *
     * @returns {*}
     */
    updateInspectionPeriodicity(viewModel) {
        let liftTypeId     = viewModel.model.lift_type_id;
        let buildingTypeId = viewModel.model.building_type_id;

        viewModel.model.inspection_periodicity = null;

        if (isNumeric(liftTypeId) && isNumeric(buildingTypeId)) {
            this.inspectionPeriodicitiesRepository
                .forLiftTypeAndBuildingType(liftTypeId, buildingTypeId)
                .then(inspectionPeriodicity => {
                    if (isNumeric(inspectionPeriodicity)) {
                        viewModel.model.inspection_periodicity = inspectionPeriodicity;
                    }
                });
        }
    }

    /**
     * Opens the installing company creation modal
     *
     * @param viewModel
     */
    createNewInstallingCompany(viewModel) {
        this.dialogService
            .open({ viewModel: CreateInstallingCompanyModal })
            .whenClosed((response) => {
                if (! response.wasCancelled) {
                    this.installing_company_id.instance.fetchData().then(() => viewModel.model.installing_company_id = response.output);
                }
            });
    }

}
