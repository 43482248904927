import { bindable, inject, useView }         from 'aurelia-framework';
import { PLATFORM }                          from 'aurelia-pal';
import { AppContainer }                      from 'resources/services/app-container';
import { AwaitingValidationLiftsRepository } from 'modules/lifts/awaiting-validation/services/repository';
import { BaseFormViewModel }                 from 'base-form-view-model';
import { DialogService }                     from 'aurelia-dialog';
import { FormSchema }                        from 'modules/lifts/awaiting-validation/validate/form-schema';
import { LiftFilesRepository }               from 'modules/lifts/lifts/services/files-repository';
import { ValidateLiftDialog }                from 'resources/elements/html-elements/dialogs/validate-lift-dialog';

@useView(PLATFORM.moduleName('modules/lifts/lifts/form.html'))
@inject(AppContainer, DialogService, FormSchema, AwaitingValidationLiftsRepository, LiftFilesRepository)
export class ValidateLift extends BaseFormViewModel {

    @bindable headerTitle = 'form.title.validate-record';
    @bindable formId      = 'lifts-awaiting-validation-edit-form';

    alert  = {};
    model  = {};
    schema = {};

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogService
     * @param formSchema
     * @param repository
     * @param filesRepository
     */
    constructor(appContainer, dialogService, formSchema, repository, filesRepository) {
        super(appContainer);

        this.dialogService   = dialogService;
        this.formSchema      = formSchema;
        this.repository      = repository;
        this.filesRepository = filesRepository;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'lifts.lifts.manage',
            'lifts.lifts.edit',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params, navigationInstruction) {
        super.activate(navigationInstruction);

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.fetchData(params).then((response) => {
            this.liftIdentificationSchema  = this.formSchema.liftIdentificationSchema(this);
            this.liftCharacteristicsSchema = this.formSchema.liftCharacteristicsSchema(this);
            this.globalButtonsSchema       = this.formSchema.globalButtonsSchema(this);

            return response;
        });
    }

    /**
     * Submits view form
     */
    submit() {
        this.alert = null;

        this.dialogService
            .open({ viewModel: ValidateLiftDialog })
            .whenClosed(dialogResponse => {
                if (!dialogResponse.wasCancelled) {
                    return this.repository
                        .update(this.model.id, this.model)
                        .then(response => this.afterSubmitCallback(response))
                        .then(response => response.status === true ? this.appContainer.router.navigateToRoute('lifts.awaiting-validation.index') : false);
                }
            });

        return false;
    }

    /**
     * Handles the submit form response
     *
     * @param response
     */
    afterSubmitCallback(response) {
        if (response.status === true) {
            this.appContainer.notifier.successNotice(response.message);

            this.initialModel.assign(this.model);
        } else {
            this.alert = this.alertMessage(response.status, response.message, response.errors);
        }

        return response;
    }

    /**
     * Fetches data from server
     *
     * @param {id}
     *
     * @returns {Promise.<*>|Promise}
     */
    fetchData({id}) {
        return this.repository.find(id).then((response) => {
            this.initialModel.assign(response);
            this.model.assign(response);

            return response;
        });
    }

}
