import { inject }                                  from 'aurelia-framework';
import { Lift }                                    from 'modules/lifts/models/lift';
import { BuildingTypesRepository }                 from 'modules/management/building-types/services/repository';
import { ControllingEntitiesRepository }           from 'modules/entities/controlling-entities/services/repository';
import { InstallingCompaniesRepository }           from 'modules/entities/installing-companies/services/repository';
import { LiftStatus }                              from 'modules/management/models/lift-status';
import { LiftTypesRepository }                     from 'modules/management/lift-types/services/repository';
import { ManagingEntitiesRepository }              from 'modules/entities/managing-entities/services/repository';
import { SingleFormSchema as LocationsFormSchema } from 'modules/administration/locations/reusable/single-form-schema';
import { EditLiftCode }                            from 'modules/lifts/lifts/code';
import { DialogService }                           from 'aurelia-dialog';

@inject(
    LiftTypesRepository,
    BuildingTypesRepository,
    InstallingCompaniesRepository,
    ManagingEntitiesRepository,
    ControllingEntitiesRepository,
    LocationsFormSchema,
    DialogService,
)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {};

    /**
     * Returns a new instance of the model
     *
     * @returns {Lift}
     */
    model() {
        let model = new Lift();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Constructor
     *
     * @param liftTypesRepository
     * @param buildingTypesRepository
     * @param installingCompaniesRepository
     * @param managingEntitiesRepository
     * @param controllingEntitiesRepository
     * @param locationsFormSchema
     * @param dialogService
     */
    constructor(
        liftTypesRepository,
        buildingTypesRepository,
        installingCompaniesRepository,
        managingEntitiesRepository,
        controllingEntitiesRepository,
        locationsFormSchema,
        dialogService,
    ) {
        this.liftTypesRepository           = liftTypesRepository;
        this.buildingTypesRepository       = buildingTypesRepository;
        this.installingCompaniesRepository = installingCompaniesRepository;
        this.managingEntitiesRepository    = managingEntitiesRepository;
        this.controllingEntitiesRepository = controllingEntitiesRepository;
        this.locationsFormSchema           = locationsFormSchema;
        this.dialogService                 = dialogService;
    }

    /**
     * Returns lift identification form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    liftIdentificationSchema(viewModel) {
        this.code = {
            type:       'text',
            key:        'code',
            label:      'form.field.lift-number',
            size:       4,
            hidden:     viewModel.model.isAwaitingValidation(),
            required:   false,
            attributes: {
                readonly: true,
            },
            inputGroup: {
                position: 'right',
                button:   {
                    title:   'form.button.edit-number',
                    action:  () => this.dialogService.open({
                        viewModel: EditLiftCode,
                        model:     viewModel.model,
                    }).whenClosed((response) => {
                        if (!response.wasCancelled) {
                            viewModel.initialModel.district_id      = viewModel.model.district_id;
                            viewModel.initialModel.county_id        = viewModel.model.county_id;
                            viewModel.initialModel.parish_id        = viewModel.model.parish_id;
                            viewModel.initialModel.code_incrementer = viewModel.model.code_incrementer;
                            viewModel.initialModel.code             = viewModel.model.code;
                        }
                    }),
                    icon:    {class: 'icon-pencil7'},
                    visible: true,
                },
            },
        };

        this.installing_company_id = {
            type:         'select2',
            key:          'installing_company_id',
            label:        'form.field.installing-company',
            size:         4,
            options:      [],
            remoteSource: this.installingCompaniesRepository.all.bind(this.installingCompaniesRepository),
        };

        this.controlling_entity_id = {
            type:         'select2',
            key:          'controlling_entity_id',
            label:        'form.field.controlling-entity',
            size:         4,
            options:      [],
            remoteSource: this.controllingEntitiesRepository.all.bind(this.controllingEntitiesRepository),
            hidden:       !(viewModel.appContainer.authenticatedUser.can(['lifts.lifts.manage']) || viewModel.appContainer.authenticatedUser.belongsToManagingEntity()),
            attributes:   {
                disabled: !viewModel.appContainer.authenticatedUser.can(['lifts.lifts.manage']),
            },
        };

        this.managing_entity_id = {
            type:         'select2',
            key:          'managing_entity_id',
            label:        'form.field.managing-entity',
            size:         4,
            options:      [],
            remoteSource: this.managingEntitiesRepository.all.bind(this.managingEntitiesRepository),
            attributes:   {
                disabled: true,
            },
        };

        this.address = {
            type:  'text',
            key:   'address',
            label: 'form.field.address',
            size:  12,
        };

        this.zip_code = {
            type:  'text',
            key:   'zip_code',
            label: 'form.field.zip-code',
            size:  2,
        };

        this.locality = {
            type:  'text',
            key:   'locality',
            label: 'form.field.locality',
            size:  6,
        };

        this.latitude = {
            type:  'text',
            key:   'latitude',
            label: 'form.field.latitude',
            size:  2,
        };

        this.longitude = {
            type:  'text',
            key:   'longitude',
            label: 'form.field.longitude',
            size:  2,
        };

        return [
            [this.code],
            [this.installing_company_id, this.controlling_entity_id, this.managing_entity_id],
            ...this.locationsFormSchema.schema(viewModel.model, true, !viewModel.model.isAwaitingValidation()),
            [this.address],
            [this.zip_code, this.locality, this.latitude, this.longitude],
        ];
    }

    /**
     * Returns lift characteristics form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    liftCharacteristicsSchema(viewModel) {
        this.lift_type_id = {
            type:         'select2',
            key:          'lift_type_id',
            label:        'form.field.lift-type',
            size:         6,
            options:      [],
            remoteSource: this.liftTypesRepository.all.bind(this.liftTypesRepository),
            observers:    [
                () => {
                    if (this.applicable_regulation_id.instance) {
                        this.applicable_regulation_id.options.splice(0, this.applicable_regulation_id.options.length);
                        this.applicable_regulation_id.instance.fetchData();
                    }
                },
            ],
        };

        this.building_type_id = {
            type:         'select2',
            key:          'building_type_id',
            label:        'form.field.building-type',
            size:         6,
            options:      [],
            remoteSource: this.buildingTypesRepository.all.bind(this.buildingTypesRepository),
        };

        this.applicable_regulation_id = {
            type:                   'select2',
            key:                    'applicable_regulation_id',
            label:                  'form.field.applicable-regulation',
            size:                   6,
            options:                [],
            remoteSource:           this.liftTypesRepository.applicableRegulations.bind(this.liftTypesRepository),
            remoteSourceParameters: () => viewModel.model.lift_type_id,
            hidden:                 !(viewModel.appContainer.authenticatedUser.can(['lifts.lifts.manage']) || viewModel.appContainer.authenticatedUser.belongsToManagingEntity()),
        };

        this.capacity = {
            type:  'number',
            key:   'capacity',
            label: 'form.field.capacity(kg)',
            size:  3,
        };

        this.person_capacity = {
            type:  'number',
            key:   'person_capacity',
            label: 'form.field.capacity(persons)',
            size:  3,
        };

        this.width = {
            type:  'number',
            key:   'width',
            label: 'form.field.width(mm)',
            size:  3,
        };

        this.unevenness = {
            type:  'number',
            key:   'unevenness',
            label: 'form.field.unevenness(degrees)',
            size:  3,
        };

        this.velocity = {
            type:  'number',
            key:   'velocity',
            label: 'form.field.velocity(m/s)',
            size:  3,
        };

        this.number_of_floors = {
            type:  'number',
            key:   'number_of_floors',
            label: 'form.field.number-of-floors',
            size:  3,
        };

        this.number_of_accesses = {
            type:  'number',
            key:   'number_of_accesses',
            label: 'form.field.number-of-accesses',
            size:  3,
        };

        this.inspection_periodicity = {
            type:       'number',
            key:        'inspection_periodicity',
            label:      'form.field.inspection-periodicity(years)',
            size:       3,
            attributes: {
                readonly: true,
            },
        };

        this.certificate_deadline = {
            type:       'text',
            key:        'certificate_deadline',
            label:      'form.field.certificate-deadline',
            size:       3,
            attributes: {
                readonly: true,
            },
        };

        this.status_id = {
            type:         'select2',
            key:          'status_id',
            label:        'form.field.status',
            size:         3,
            hidden:       viewModel.model.status_id === LiftStatus.AWAITING_VALIDATION,
            options:      [],
            remoteSource: () => Promise.resolve(viewModel.liftStatuses),
        };

        return [
            [this.lift_type_id, this.building_type_id],
            [this.applicable_regulation_id, this.inspection_periodicity, this.certificate_deadline],
            [this.capacity, this.person_capacity, this.width, this.unevenness],
            [this.velocity, this.number_of_floors, this.number_of_accesses, this.status_id],
        ];
    }

    /**
     * Returns global buttons form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    globalButtonsSchema(viewModel) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('lifts.lifts.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.validateButton = {
            type:       'submit',
            label:      'form.button.save-and-validate',
            action:     () => viewModel.validate(),
            hidden:     !viewModel.model.allows_validation,
            attributes: {
                class: 'btn btn-success',
            },
            icon:       {
                attributes: {
                    class: 'icon-checkmark3',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.submitButton,
                this.validateButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
