import { inject }                                  from 'aurelia-framework';
import { NewLiftCode }                             from 'modules/lifts/models/new-lift-code';
import { LiftCodesRepository }                     from 'modules/lifts/lifts/code/services/repository';
import { SingleFormSchema as LocationsFormSchema } from 'modules/administration/locations/reusable/single-form-schema';

@inject(LiftCodesRepository, LocationsFormSchema)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {};

    /**
     * Constructor
     *
     * @param liftCodesRepository
     * @param locationsFormSchema
     */
    constructor(liftCodesRepository, locationsFormSchema) {
        this.liftCodesRepository = liftCodesRepository;
        this.locationsFormSchema = locationsFormSchema;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {NewLiftCode}
     */
    model() {
        let model = new NewLiftCode();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {[*]}
     */
    schema(viewModel) {
        let locationsSettings = {
            district: {
                id:   'lift_code_edition_district_id',
                size: 12,
            },
            county:   {
                id:   'lift_code_edition_county_id',
                size: 12,
            },
            parish:   {
                id:   'lift_code_edition_parish_id',
                size: 12,
            },
        };

        this.code_incrementer = {
            type:       'number',
            key:        'code_incrementer',
            id:         'lift_code_edition_code_incrementer',
            label:      'form.field.new-number',
            size:       6,
            inputGroup: {
                position: 'right',
                button:   {
                    title:   'form.button.generate-number',
                    action:  () => this.generateCodeIncrementer(viewModel),
                    icon:    {class: 'icon-reload-alt'},
                    visible: true,
                },
            },
        };

        return [
            ...this.locationsFormSchema.withSettings(locationsSettings).schema(viewModel.model),
            [this.code_incrementer],
        ];
    }

    /**
     * Requests the generation of the next lift code incrementer
     *
     * @param {*} viewModel
     */
    generateCodeIncrementer(viewModel) {
        viewModel.alert = null;

        if (!viewModel.model.county_id) {
            return viewModel.alert = viewModel.warningAlertMessage('text.error-message.a-county-must-be-selected-to-generate-new-lift-number');
        }

        return this.liftCodesRepository.generateCodeIncrementer(viewModel.model.county_id).then(response => viewModel.model.code_incrementer = response.code_incrementer);
    }

}
