import { bindable, inject, PLATFORM } from 'aurelia-framework';
import { AppContainer }               from 'resources/services/app-container';
import { BaseFormViewModel }          from 'base-form-view-model';
import { LiftsRepository }            from 'modules/lifts/lifts/services/repository';
import { FormSchema }                 from 'modules/lifts/lifts/create/form-schema';

@inject(AppContainer, LiftsRepository, FormSchema)
export class CreateLift extends BaseFormViewModel {

    @bindable headerTitle = 'form.title.create-new-record';
    @bindable formId      = 'lifts.lifts.creation-form';

    alert  = {};
    model  = {};
    schema = {};

    creating = true;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     */
    constructor(appContainer, repository, formSchema) {
        super(appContainer);

        this.repository = repository;
        this.formSchema = formSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/lifts/lifts/form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'lifts.lifts.manage',
            'lifts.lifts.create',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params) {
        super.activate();

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.fetchData().then(() => {
            this.liftIdentificationSchema  = this.formSchema.liftIdentificationSchema(this, true);
            this.liftCharacteristicsSchema = this.formSchema.liftCharacteristicsSchema(this, true);
            this.globalButtonsSchema       = this.formSchema.globalButtonsSchema(this, true);

            return true;
        });
    }

    /**
     * Submits view form
     */
    submit() {
        this.repository
            .create(this.model)
            .then(response => {
                if (response.status === true) {
                    this.appContainer.notifier.successNotice(response.message);

                    this.initialModel.assign(this.model);

                    this.appContainer.router.navigateToRoute('lifts.lifts.edit', { id: response.model.id });
                } else {
                    this.alert = this.alertMessage(response.status, response.message, response.errors);
                }
            });
    }

}
