import { bindable, inject }  from 'aurelia-framework';
import { AppContainer }      from 'resources/services/app-container';
import { BaseFormViewModel } from 'base-form-view-model';
import { DialogController }  from 'aurelia-dialog';
import { LiftsRepository }   from 'modules/lifts/lifts/services/repository';

@inject(AppContainer, DialogController, LiftsRepository)
export class ViewLiftHistory extends BaseFormViewModel {

    formId = 'lifts.lifts.history.view-form';

    @bindable alert;
    @bindable settings = {
        title:   'form.title.view-lift-history',
        size:    'modal-md',
        buttons: false,
    };

    timeline = [];

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogController
     * @param repository
     */
    constructor(appContainer, dialogController, repository) {
        super(appContainer);

        this.dialogController = dialogController;
        this.repository       = repository;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'lifts.lifts.manage',
            'lifts.lifts.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params) {
        this.settings.subtitle = params.code;

        return this.fetchData(params);
    }

    /**
     * Fetches data from server
     *
     * @param {id}
     *
     * @returns {Promise.<*>|Promise}
     */
    fetchData({ id }) {
        return this.repository.timeline(id).then(response => this.timeline = response);
    }

    /**
     * Closes the modal
     */
    cancel() {
        return this.dialogController.cancel();
    }

}
