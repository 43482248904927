import { inject } from 'aurelia-framework';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class LiftCodesRepository {

    /**
     * Constructor
     *
     * @param httpClient
     */
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    /**
     * Generate a new code for the given county
     *
     * @param countyId
     *
     * @returns {*}
     */
    generateCode(countyId) {
        return this.httpClient.get(`lifts/lifts/code/${countyId}/generate`);
    }

    /**
     * Generate a new code incrementer for the given county
     *
     * @param countyId
     *
     * @returns {*}
     */
    generateCodeIncrementer(countyId) {
        return this.httpClient.get(`lifts/lifts/code-incrementer/${countyId}/generate`);
    }

    /**
     * Updates a record
     *
     * @param liftId
     * @param data
     *
     * @returns {*}
     */
    update(liftId, data) {
        return this.httpClient.patch(`lifts/lifts/${liftId}/code`, data);
    }

}
