import { bindable, inject, PLATFORM } from 'aurelia-framework';
import { AppContainer }               from 'resources/services/app-container';
import { BaseFormViewModel }          from 'base-form-view-model';
import { DialogService }              from 'aurelia-dialog';
import { FormSchema }                 from 'modules/lifts/lifts/edit/form-schema';
import { LiftsRepository }            from 'modules/lifts/lifts/services/repository';
import { LiftFilesRepository }        from 'modules/lifts/lifts/services/files-repository';
import { LiftStatus }                 from 'modules/management/models/lift-status';
import { LiftStatusesRepository }     from 'modules/management/lift-statuses/services/repository';
import { ValidateLiftDialog }         from 'resources/elements/html-elements/dialogs/validate-lift-dialog';

@inject(AppContainer, DialogService, FormSchema, LiftsRepository, LiftFilesRepository, LiftStatusesRepository)
export class EditLift extends BaseFormViewModel {

    @bindable headerTitle    = 'form.title.edit-record';
    @bindable newRecordRoute = 'lifts.lifts.create';
    @bindable formId         = 'lifts-lifts-edition-form';

    alert  = {};
    model  = {};
    schema = {};

    liftStatuses = [];

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogService
     * @param formSchema
     * @param repository
     * @param filesRepository
     * @param statusesRepository
     */
    constructor(appContainer, dialogService, formSchema, repository, filesRepository, statusesRepository) {
        super(appContainer);

        this.dialogService      = dialogService;
        this.formSchema         = formSchema;
        this.repository         = repository;
        this.filesRepository    = filesRepository;
        this.statusesRepository = statusesRepository;
        this.newRecordRoute     = appContainer.authenticatedUser.can(['lifts.lifts.manage', 'lifts.lifts.create']) ? this.newRecordRoute : null;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/lifts/lifts/form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'lifts.lifts.manage',
            'lifts.lifts.edit',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params) {
        super.activate();

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.fetchData(params).then(() => {
            this.liftIdentificationSchema  = this.formSchema.liftIdentificationSchema(this);
            this.liftCharacteristicsSchema = this.formSchema.liftCharacteristicsSchema(this);
            this.globalButtonsSchema       = this.formSchema.globalButtonsSchema(this);

            return true;
        });
    }

    /**
     * Handles attached event
     */
    attached() {
        super.attached();

        this.headerSubtitle = this.model.code;
    }

    /**
     * Submits view form
     */
    submit() {
        this.repository
            .update(this.model.id, this.model)
            .then(response => this.afterSubmitCallback(response));
    }

    /**
     * Submits view form
     */
    validate() {
        this.alert = null;

        this.dialogService
            .open({ viewModel: ValidateLiftDialog })
            .whenClosed(dialogResponse => {
                if (!dialogResponse.wasCancelled) {
                    return this.repository
                               .validate(this.model.id, this.model)
                               .then(response => this.afterSubmitCallback(response));
                }
            });

        return false;
    }

    /**
     * Handles the submit form response
     *
     * @param response
     */
    afterSubmitCallback(response) {
        if (response.status === true) {
            this.appContainer.notifier.successNotice(response.message);

            this.initialModel.assign(this.model);

            this.redirectToRoute('lifts.lifts.index');
        } else {
            this.alert = this.alertMessage(response.status, response.message, response.errors);
        }
    }

    /**
     * Fetches data from server
     *
     * @param {id}
     *
     * @returns {Promise.<*>|Promise}
     */
    fetchData({id}) {
        return Promise.all([
            this.repository.find(id).then((response) => {
                this.initialModel.assign(response);
                this.model.assign(response);
            }),
            this.statusesRepository.active().then((response) => {
                this.liftStatuses.splice(0, this.liftStatuses.length, ...response.filter(status => status.id !== LiftStatus.AWAITING_VALIDATION));
            }),
        ]);
    }

}
